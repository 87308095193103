<template>
  <div>
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 搜索条件 -->
      <div slot="tips" class="TitleBox">
        <div class="conditions">
          <div>下单时间：</div>
          <el-date-picker class="choose-machine" style="width: 200px; margin-right: 20px" v-model="times"
            type="daterange" align="center" :editable="false" unlink-panels size="mini"
            :default-time="['00:00:00', '23:59:59']" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
            value-format="timestamp" :picker-options="{
      disabledDate: MixinDisabledDate,
      shortcuts: MixinPickerShortcuts,
    }" @change="
      times
        ? (params.start_time = times[0] / 1000)
        : (params.start_time = '');
    times
      ? (params.end_time = times[1] / 1000)
      : (params.end_time = '');
    "></el-date-picker>
        </div>
        <div class="conditions" style="width: 220px; margin-left: 10px">
          <el-select v-model="params.keywords" placeholder="请选择关键字" clearable size="mini"
            @change="params.keywords === '' ? (params.keyvalue = '') : ''">
            <el-option v-for="item in keyTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </div>
        <div class="conditions" v-if="params.keywords" style="margin-right: 20px">
          <el-input v-model="params.keyvalue" placeholder="请输入关键字内容" class="ipt-default" size="mini"></el-input>
        </div>
        <div class="conditions">
          <div>核销状态：</div>
          <el-select v-model="params.verify_status" placeholder="请选择" clearable size="mini">
            <el-option label="全部" value></el-option>
            <el-option label="未核销" :value="1"></el-option>
            <el-option label="已核销" :value="2"></el-option>
            <el-option label="已过期" :value="3"></el-option>
          </el-select>
        </div>
        <div class="conditions" style="margin-left: 30px;">
          <el-button @click="searchOrderList" type="primary" class="btn-default" size="mini">搜索</el-button>
        </div>
        <div class="conditions" style="margin-left: 20px">
          <el-button @click="GET_tradeOrdersExport" type="primary" class="btn-default" size="mini">导出</el-button>
        </div>
      </div>

      <!-- 表格上部按钮选项卡  -->
      <div slot="toolbar" style="display: flex; align-items: center">
        <el-tabs v-model="params.order_status" type="card" class="elTabBox" @tab-click="GET_tradeOrders">
          <el-tab-pane label="全部" name></el-tab-pane>
          <el-tab-pane label="待付款" name="CONFIRM"></el-tab-pane>
          <el-tab-pane label="待参加" name="PAID_OFF"></el-tab-pane>
          <el-tab-pane label="进行中" name="IN"></el-tab-pane>
          <el-tab-pane label="已完成" name="COMPLETE"></el-tab-pane>
          <el-tab-pane label="待退款" name="REFUNDING"></el-tab-pane>
          <el-tab-pane label="已退款" name="REFUNDED"></el-tab-pane>
          <el-tab-pane label="已取消" name="CANCELLED"></el-tab-pane>
        </el-tabs>
        <div style="color: #606266; margin-left: 20px">
          订单小计：{{ tableData.data_total }}笔，金额小计：{{
      tableData.sum
    }}元
        </div>
      </div>

      <template slot="table-columns">
        <!-- 活动名称 -->
        <el-table-column label="活动名称" width="225" fixed="left">
          <template slot-scope="scope">
            <div class="imgBox">
              <img :src="scope.row.original" />
              <div :title="scope.row.name">{{ scope.row.name }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- 活动编号 -->
        <el-table-column prop="sku_sn" label="活动编号" />
        <!-- 活动一级分类名称 -->
        <el-table-column prop="cat_first_name" label="活动一级分类名称" />
        <!-- 活动二级分类名称 -->
        <el-table-column label="活动二级分类名称">
          <template slot-scope="scope">
            {{ scope.row.cat_second_name === null ? '————' : scope.row.cat_second_name }}
          </template>
        </el-table-column>
        <!-- 商家名称 -->
        <el-table-column prop="seller_name" label="商家名称" />
        <!-- 活动编号 -->
        <!-- <el-table-column prop="sku_sn" label="活动编号" /> -->
        <!-- 期次类型 -->
        <el-table-column label="期次类型">
          <template slot-scope="scope">
            {{ scope.row.ticket_num === 0 ? '不多期' : '多期' }}
          </template>
        </el-table-column>
        <!-- 日期/期次 -->
        <el-table-column label="日期/期次" width="185">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.start_date">
                {{
      scope.row.ticket_num === 0
        ? "单期"
        : "第" + scope.row.ticket_num + "期"
    }}
              </div>
              <div v-if="scope.row.start_date">
                {{
      MixinUnixToDate("", "", scope.row.start_date).split(" ")[0]
    }}至{{
        MixinUnixToDate("", "", scope.row.end_date).split(" ")[0]
      }}
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 时间/场次 -->
        <el-table-column label="时间/场次" width="160">
          <template slot-scope="scope">
            <div>
              <div v-if="scope.row.time_start">
                {{
      Number(scope.row.time_start.split(":")[0]) >= 12
        ? "下午场"
        : "上午场"
    }}
              </div>
              <div v-if="scope.row.time_start">
                {{ scope.row.time_start }}-{{ scope.row.time_end }}
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 规格 -->
        <el-table-column prop="ticket_name" label="规格" />
        <!-- 是否支持退款 -->
        <el-table-column prop="refund_type" label="是否支持退款">
          <template slot-scope="scope">
            <div>
              <div>
                {{ scope.row.refund_type === 0 ? "不支持退款" : "支持退款" }}
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- 客户/手机号 -->
        <el-table-column label="手机号">
          <template slot-scope="scope">
            <div>
              <!-- <div>{{ scope.row.member_name }}</div> -->
              <div>{{ scope.row.phone }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- 订单编号 -->
        <el-table-column prop="trade_sn" label="订单编号" width="160" />
        <!-- 下单时间 -->
        <el-table-column prop="create_date" :formatter="MixinUnixToDate" label="下单时间" width="160" />
        <!-- 数量 -->
        <el-table-column prop="num" label="数量" />
        <!-- 付款方式 -->
        <el-table-column prop="payment_method_name" label="付款方式" />
        <!-- 原价 -->
        <!-- <el-table-column label="原价">
          <template slot-scope="scope">
            <div>{{ scope.row.mktprice ? scope.row.mktprice : 0 }}元</div>
          </template>
        </el-table-column> -->
        <!-- 销售价 -->
        <el-table-column label="销售价">
          <template slot-scope="scope">
            <div>{{ scope.row.price }}元</div>
          </template>
        </el-table-column>
        <!-- 优惠券 -->
        <el-table-column label="优惠券">
          <template slot-scope="scope">
            <div>{{ scope.row.cost - scope.row.order_price }}元</div>
          </template>
        </el-table-column>
        <!-- 商家优惠券类型 -->
        <el-table-column label="商家优惠券类型">
          <template slot-scope="scope">
            <div>
              {{
      scope.row.coupon_type === 1
        ? "面额型"
        : scope.row.coupon_type === 2
          ? "满减型"
          : scope.row.coupon_type === 3
            ? "折扣型"
            : "无"
    }}
            </div>
          </template>
        </el-table-column>
        <!-- 客户实际付款 -->
        <el-table-column prop="order_price" label="订单金额" />
        <!-- 订单状态 -->
        <el-table-column label="订单状态">
          <template slot-scope="scope">
            <div>
              {{
        scope.row.order_status === "CONFIRM"
          ? "待付款"
          : scope.row.order_status === "PAID_OFF"
            ? "待参加"
            : scope.row.order_status === "IN"
              ? "进行中"
              : scope.row.order_status === "COMPLETE"
                ? "已完成"
                : scope.row.order_status === "REFUNDING"
                  ? "待退款"
                  : scope.row.order_status === "REFUNDED"
                    ? "已退款"
                    : scope.row.order_status === "CANCELLED"
                      ? "已取消"
                      : ""
      }}
            </div>
          </template>
        </el-table-column>
        <!-- 微信退款状态 -->
        <el-table-column label="微信退款状态">
          <template slot-scope="scope">
            <div>
              {{ scope.row.order_status === "REFUNDING" ? "待退款" : scope.row.order_status === "REFUNDED" ? "已退款" : "" }}
            </div>
          </template>
        </el-table-column>
        <!-- 第三方支付单号 -->
        <el-table-column prop="out_trade_no" label="第三方支付单号" />
        <!-- 结算状态 -->
        <el-table-column label="结算状态">
          <template slot-scope="scope">
            {{ scope.row.bill_status === 0 ? '未结算' : '已结算' }}
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="订单备注信息" />
        <!-- 电子券码 -->
        <el-table-column prop="eticket_no" label="电子券码" width="120" />
        <!-- 核销状态 -->
        <el-table-column label="核销状态">
          <template slot-scope="scope">
            <!-- 0 未核销  1已核销  2已过期 -->
            <div v-if="scope.row.verify_status == 1">未核销</div>
            <div v-if="scope.row.verify_status == 2">已核销</div>
            <div v-if="scope.row.verify_status == 3">已过期</div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="160" fixed="right">
          <template slot-scope="scope">
            <div class="buttonBox">
              <el-button @click="openDialog('orderDetails', scope.row)" type="text" size="mini"
                style="margin-bottom: 5px">查看详情</el-button>
              <el-button @click="openDialog('travelers', scope.row)" type="text" size="mini" style="margin-bottom: 5px"
                v-if="scope.row.order_status === 'PAID_OFF' ||
      scope.row.order_status === 'IN' ||
      scope.row.order_status === 'COMPLETE' ||
      scope.row.order_status === 'REFUNDING' ||
      scope.row.order_status === 'REFUNDED'
      ">查看出行人及联系人</el-button>
            </div>
          </template>
        </el-table-column>
      </template>
      <el-pagination v-if="tableData.data" slot="pagination" @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size" layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_orderList from "@/api/orderList";
import * as Export2Excel from "@/utils/exportXlsx";

export default {
  name: "activityList",
  data () {
    return {
      //  列表loading状态
      loading: false,

      // 列表参数
      params: {
        page_no: 1,
        page_size: 10,
        start_time: null,
        end_time: null,
        keywords: null,
        keyvalue: null,
        order_status: "0",
        verify_status: '',
      },

      //  列表数据
      tableData: [],

      // 查询时间
      times: [],

      // 关键字类型搜索
      keyTypeList: [
        { id: "trade_sn", name: "订单编号" },
        // { id: "member_name", name: "客户姓名" },
        { id: "phone", name: "客户手机号" },
        { id: "name", name: "活动名称" },
        { id: "sku_sn", name: "活动编号" },
        { id: "e_ticket_no", name: "电子券码" },
        { id: "seller_name", name: "商户名称" },
      ],
    };
  },
  watch: {
    $route () {
      this.params.page_no = 1;
      this.params.page_size = 10;
      this.params.start_time = null;
      this.params.end_time = null;
      this.params.keywords = null;
      this.params.keyvalue = null;
      this.params.order_status = '0';
      this.params.verify_status = '';
      this.times = [];
      if (this.$route.query.mem) {
        this.GET_tradeOrders_agent(this.$route.query.mem);
      } else {
        // 获取订单列表
        this.GET_tradeOrders();
      }
    },
  },
  mounted () {
    if (this.$route.query.mem) {
      this.GET_tradeOrders_agent(this.$route.query.mem);
    } else {
      // 获取订单列表
      this.GET_tradeOrders();
    }
  },
  activated () {
    if (this.$route.query.mem) {
      this.GET_tradeOrders_agent(this.$route.query.mem);
    } else {
      // 获取订单列表
      this.GET_tradeOrders();
    }
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_tradeOrders();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_tradeOrders();
    },

    /** 搜索订单列表 */
    searchOrderList () {
      this.params.page_no = 1
      this.GET_tradeOrders()
    },

    /** 获取订单列表 */
    GET_tradeOrders () {
      if (this.$route.query.mem) {
        this.GET_tradeOrders_agent(this.$route.query.mem);
        return;
      }
      var params = {};
      if (this.params.order_status !== "0") {
        params.order_status = this.params.order_status;
      }
      if (this.params.keywords) {
        params.keywords = this.params.keywords;
        params.keyvalue = this.params.keyvalue;
      }
      if (this.params.start_time) {
        params.start_time = this.params.start_time;
        params.end_time = this.params.end_time;
      }
      if (this.params.verify_status !== "") {
        params.verify_status = this.params.verify_status;
      }
      params.page_no = this.params.page_no;
      params.page_size = this.params.page_size;
      API_orderList.tradeOrders(params).then((res) => {
        this.tableData = res;
        console.log(res);
      });
    },
    // 分销代理人详情的查看订单列表
    GET_tradeOrders_agent (agentId) {
      var params = {};
      if (this.params.order_status !== "0") {
        params.order_status = this.params.order_status;
      }
      if (this.params.keywords) {
        params.keywords = this.params.keywords;
        params.keyvalue = this.params.keyvalue;
      }
      if (this.params.start_time) {
        params.start_time = this.params.start_time;
        params.end_time = this.params.end_time;
      }
      params.page_no = this.params.page_no;
      params.page_size = this.params.page_size;
      params.agent_id = agentId;
      API_orderList.tradeOrdersAgentId(params).then((res) => {
        this.tableData = res;
      });
    },

    /** 导出订单列表 */
    GET_tradeOrdersExport () {
      var params = {};
      if (this.params.order_status !== "0") {
        params.order_status = this.params.order_status;
      }
      if (this.params.keywords) {
        params.keywords = this.params.keywords;
        params.keyvalue = this.params.keyvalue;
      }
      params.start_time = this.params.start_time;
      params.end_time = this.params.end_time;
      API_orderList.tradeOrdersExport(params).then((res) => {
        var excelData = [];
        res.forEach((item) => {
          var excelDataRow = [];
          excelDataRow.push(item.name);
          excelDataRow.push(item.cat_first_name)
          excelDataRow.push(item.cat_second_name)
          excelDataRow.push(item.seller_name);
          excelDataRow.push(item.sku_sn);
          excelDataRow.push(
            item.start_date
              ? this.MixinUnixToDate("", "", item.start_date).split(" ")[0] +
              "至" +
              this.MixinUnixToDate("", "", item.end_date).split(" ")[0]
              : ""
          );
          excelDataRow.push(
            item.ticket_num === 0 ? "单期" : "第" + item.ticket_num + "期"
          );
          excelDataRow.push(item.time_start + "-" + item.time_end);
          excelDataRow.push(
            item.time_start
              ? Number(item.time_start.split(":")[0]) >= 12
                ? "下午场"
                : "上午场"
              : ""
          );
          excelDataRow.push(item.ticket_name);
          excelDataRow.push(item.refund_type === 0 ? "不支持退款" : "支持退款");
          // excelDataRow.push(item.member_name);
          excelDataRow.push(item.phone);
          excelDataRow.push(item.trade_sn);
          excelDataRow.push(this.MixinUnixToDate("", "", item.create_date));
          excelDataRow.push(item.num);
          excelDataRow.push(item.payment_method_name);
          // excelDataRow.push(item.mktprice ? item.mktprice + "元" : "");
          excelDataRow.push(item.price ? item.price + "元" : "");
          excelDataRow.push(item.cost - item.order_price + "元");
          excelDataRow.push(
            item.coupon_type === 1
              ? "面额型"
              : item.coupon_type === 2
                ? "满减型"
                : item.coupon_type === 3
                  ? "折扣型"
                  : "无"
          );
          excelDataRow.push(item.order_price + "元");
          excelDataRow.push(
            item.order_status === "CONFIRM"
              ? "待付款"
              : item.order_status === "PAID_OFF"
                ? "待参加"
                : item.order_status === "IN"
                  ? "进行中"
                  : item.order_status === "COMPLETE"
                    ? "已完成"
                    : item.order_status === "REFUNDING"
                      ? "待退款"
                      : item.order_status === "REFUNDED"
                        ? "已退款"
                        : item.order_status === "CANCELLED"
                          ? "已取消"
                          : ""
          );
          excelDataRow.push(item.out_trade_no)
          excelDataRow.push(item.bill_status === 0 ? '未结算' : '已结算')
          excelDataRow.push(item.remarks);
          excelDataRow.push(item.eticket_no);
          excelDataRow.push(item.verify_status === "1" ? "未核销" : item.verify_status === "2" ? "已核销" : "已过期");
          // 出行人
          let base_travelers = ''
          if (item.base_travelers) {
            item.base_travelers.forEach(item => {
              base_travelers += item.chinese_name + ',' + item.phone + '\n'
            })
          }
          excelDataRow.push(base_travelers);
          // 紧急联系人
          let base_emergenciers = ''
          if (item.base_emergenciers) {
            item.base_emergenciers.forEach(item => {
              base_emergenciers += item.name + ',' + item.phone + '\n'
            })
          }
          excelDataRow.push(base_emergenciers);
          excelData.push(excelDataRow);
        });
        Export2Excel.export_json_to_excel(
          [
            "活动名称",
            "活动一级分类",
            "活动二级分类",
            "商家名称",
            "活动编号",
            "日期",
            "期次",
            "时间",
            "场次",
            "规格",
            "是否支持退款",
            // "客户",
            "手机号",
            "订单编号",
            "下单时间",
            "数量",
            "付款方式",
            // "原价",
            "销售价",
            "优惠券",
            "商家优惠券类型",
            "订单金额",
            "订单状态",
            "第三方平台单号",
            "结算状态",
            "订单备注信息",
            "电子券码",
            "核销状态",
            "出行人",
            "紧急联系人"
          ],
          excelData,
          "订单列表数据"
        );
      });
    },

    /** 跳转页面 */
    openDialog (type, row) {
      if (type === "travelers") {
        console.log(this.$route)
        this.$router.push({
          name: "活动参与人详情",
          params: { order_id: row.order_id },
        });
        return false;
      } else if (type === "orderDetails") {
        this.$router.push({
          name: "订单详情",
          params: { order_id: row.order_id },
        });
        return false;
      }
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
::v-deep .el-table td:not(.is-left) {
  text-align: center;
}

.TitleBox {
  display: flex;

  .conditions {
    display: flex;
    align-items: center;

    div {
      font-size: 15px;
      font-weight: 500;
      color: rgba(68, 68, 68, 1);
    }

    .choose-machine {
      width: 60%;
    }
  }
}

.imgBox {
  display: flex;
  align-items: center;

  img {
    width: 64px;
    height: 48px;
    margin-right: 10px;
  }

  div {
    width: 150px;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .toolbar {
  padding-bottom: 0 !important;
}

.elTabBox {
  ::v-deep .el-tabs__item {
    background: #fff;
  }

  ::v-deep .is-active {
    background: #008080;
    color: #fff;
  }

  ::v-deep #tab-first {
    border-radius: 4px 0 0 4px;
  }

  ::v-deep #tab-second {
    border-radius: 0 4px 4px 0;
  }
}
</style>
